"use client"

import { Button } from "@/components/ui/button"
import { motion } from 'framer-motion'
import Image from "next/image"
import Link from "next/link"
import { FeatureCards } from './FeatureCards'
import { TrustIndicators } from './TrustIndicators'
import { Printer } from 'lucide-react'

export function ProductHero() {
  return (
    <div className="bg-gradient-to-br from-blue-50 to-indigo-100 overflow-hidden py-12 sm:py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 sm:py-16">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 items-center">
          <motion.div 
            className="text-center lg:text-left" // Center on mobile, left-align on larger screens
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            <h1 className="text-4xl sm:text-5xl md:text-6xl font-extrabold text-gray-900 mb-6">
              <span className="block">Perfect Photos for</span>
              <span className="block bg-clip-text text-transparent bg-gradient-to-r from-indigo-600 to-blue-600">Every Document</span>
            </h1>
            <p className="text-xl sm:text-2xl text-gray-600 mb-4 sm:mb-8">
              Get your passport, visa, or ID photo in just 3 minutes. Guaranteed acceptance or your money back!
            </p>
            
            {/* Updated paragraph for same-day printing options */}
            <div className="text-sm text-gray-500 flex items-center justify-center lg:justify-start flex-wrap mb-6 sm:mb-8">
              <Printer className="w-4 h-4 mr-2 text-indigo-500" />
              <span>Same-day printing at </span>
              <Link href="https://www.snap2pass.com/post/print-passport-photos-walgreens-guide" className="text-indigo-600 hover:underline mx-1">Walgreens</Link> 
              <span>/</span>
              <Link href="https://www.snap2pass.com/post/how-to-print-same-day-photos-at-cvs" className="text-indigo-600 hover:underline mx-1">CVS</Link>
            </div>

            <div className="flex flex-col items-center lg:items-start">
              <Link href="#doc_types">
                <Button className="text-lg py-3 px-8 bg-gradient-to-r from-indigo-500 to-blue-500 hover:from-indigo-600 hover:to-blue-600 text-white font-semibold rounded-full transition-all duration-300 transform hover:scale-105">
                  Choose Your Document Type
                </Button>
              </Link>
              <p className="mt-4 text-sm text-gray-500">Instant results, 24/7 customer support</p>
            </div>
          </motion.div>
          <motion.div 
            className="relative max-w-md mx-auto lg:max-w-full"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.6, delay: 0.2 }}
          >
            <motion.div
              className="absolute -inset-4 bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 rounded-full opacity-75 blur-2xl"
              animate={{
                scale: [1, 1.2, 1],
                opacity: [0.5, 0.8, 0.5],
              }}
              transition={{
                duration: 3,
                repeat: Infinity,
                repeatType: "reverse",
                ease: "easeInOut",
              }}
            />
            <motion.div 
              className="relative"
              whileHover={{ scale: 1.05 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <Image
                src="/assets/snap.png"
                alt="Snap2Pass app demonstration with passport and approved photos"
                width={500}
                height={500}
                className="w-full h-auto rounded-2xl drop-shadow-md"
              />
              <div className="absolute -bottom-8 left-1/2 transform -translate-x-1/2 bg-indigo-600 text-white text-lg font-bold px-6 py-2 rounded-full shadow-lg flex items-center">
                <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <span className="mr-2">3s</span>
                <span className="text-base">⚡</span>
              </div>
            </motion.div>
          </motion.div>
        </div>
      </div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <TrustIndicators className="mt-16 sm:mt-20" />
        <FeatureCards className="mt-16 sm:mt-20" />
      </div>
    </div>
  );
}
