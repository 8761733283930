import Image from 'next/image';
import Phone from '../../public/PhoneHow.webp';
import RightPhoto from '../../public/RightPhoto.webp';
import BgRemove from '../../public/BgRemove.webp';
import CropPhoto from '../../public/CropPhoto.webp';
import CameraRounded from '../../public/CameraRounded.svg';
import DoubleCheck from '../../public/DoubleCheck.svg';
import Scissors from '../../public/Scissors.svg';
import Crop from '../../public/Crop.svg';
import { Container } from '@/components/Container';

const steps = [
  {
    img: (
      <Image
        className="rounded-3xl w-[231px] h-auto"
        src={Phone}
        alt={
          'person taking a photo with a smartphone for a passport photo maker'
        }
        quality={50}
      />
    ),
    icon: (
      <CameraRounded className="absolute -top-[7%] right-[10%] h-[50px] w-[50px]" />
    ),
    description: 'Take a photo',
  },
  {
    img: (
      <Image
        className="rounded-3xl w-[231px] h-auto"
        src={RightPhoto}
        alt={'correct way to take a photo to make a passport photo online'}
        quality={50}
      />
    ),
    icon: (
      <DoubleCheck className="absolute -top-[7%] right-[2%] h-[50px] w-[50px]" />
    ),
    description: 'AI Checks the conditions of the photo',
  },
  {
    img: (
      <Image
        className="rounded-3xl w-[231px] h-auto"
        src={BgRemove}
        alt={'removes the background to make a passport photo online'}
        quality={50}
      />
    ),
    icon: (
      <Scissors className="absolute -top-[7%] right-[2%] h-[50px] w-[50px]" />
    ),
    description: 'Removes the backgorund',
  },
  ,
  {
    img: (
      <Image
        className="rounded-3xl w-[231px] h-auto"
        src={CropPhoto}
        alt={'crops the photo to make the perfect passport photo online'}
      />
    ),
    icon: <Crop className="absolute -top-[7%] right-[2%] h-[50px] w-[50px]" />,
    description: 'Resizes and crops the photo to the right size',
  },
];

export function HowItWorks() {
  return (
    <section
      id="how_it_works"
      aria-label="Features for running your books"
      className="relative overflow-hidden bg-slate-50 py-16 sm:py-24"
    >
      <Container className="relative">
        <div className="mx-auto mb-12 text-center">
          <h2 className="text-3xl sm:text-4xl font-extrabold text-gray-900 leading-tight mb-4">
            How it <span className="bg-clip-text text-transparent bg-gradient-to-r from-indigo-600 to-blue-600">works</span>?
          </h2>
          <p className="text-lg text-gray-600">
            Take the perfect photo right from your smartphone compliance guaranteed.
          </p>
        </div>
        <div className="mx-auto grid grid-cols-2 grid-flow-row gap-4 py-10 px-2 lg:gap-5 lg:grid-cols-4 justify-center bg-slate-100 lg:px-10 rounded-2xl">
          {steps.map((step, index) => {
            return (
              <div key={`step_${index}`} className="">
                <div className="relative mx-auto max-w-fit">
                  {step?.icon}
                  {step?.img}
                </div>
                <p className="mx-auto max-w-xs text-base leading-7 text-gray-600 mt-5 flex flex-row items-center">
                  <span className="text-indigo-600 font-semibold mr-3">
                    {(++index).toString().padStart(2, '0')}
                  </span>
                  <span>{step?.description}</span>
                </p>
              </div>
            );
          })}
        </div>
      </Container>
    </section>
  );
}
