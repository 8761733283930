import { DocumentTypes } from '@/components/DocumentTypes';
import { Faqs } from '@/components/Faqs';
import Footer from '@/components/Footer';
import { Header } from '@/components/Header';
import { HowItWorks } from '@/components/HowItWorks';
import { HowtoTake } from '@/components/HowtoTake';
import { ProductHero } from '@/components/ProductHero';
import { Testimonials } from '@/components/Testimonials';
import { track_event } from '@/lib/mixpanel';
import Head from 'next/head';
import { useEffect } from 'react';

export default function Home() {
  useEffect(() => {
    const url = new URL(window.location.href);
    track_event('page_view', {
      path: '/',
      'via_google_ads:': url.searchParams.get('gclid') != undefined,
    });
  });

  return (
    <>
      <Head>
        <title>
          Online Visa & Passport Photos: Guaranteed Compliance
        </title>
        <meta
          name="description"
          content="Create Visa and Passport photos online with your smartphone with guaranteed compliance with the requirements. Visit our website now to create your photo!"
        />
        <meta
          name="ahrefs-site-verification"
          content="7ea70ae0ae4961f7be23032bb9bf46235b28d9cbdfc483f4b504ffaecea36b83"
        ></meta>
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <Header />
      <main>
        <ProductHero />
        <Testimonials/>
        <DocumentTypes />
        <HowItWorks />
        <HowtoTake />
        <Faqs />
      </main>
      <Footer />
    </>
  );
}
